import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MathJaxLoader } from '../services/mathjax.loader';

@Directive({
  selector: '[libMathJax]',
  standalone: true,
})
export class MathJaxDirective implements OnChanges {
  @Input('libMathJax') mathJax!: string | Promise<string>;

  constructor(private el: ElementRef, private mathJaxLoader: MathJaxLoader) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['mathJax']) {
      const content = await this.resolveContent(this.mathJax);
      await this.mathJaxLoader.loadMathJax(); // espera que MathJax se cargue antes de renderizar
      this.render(content);
    }
  }

  private async resolveContent(content: string | Promise<string>): Promise<string> {
    return content instanceof Promise ? await content : content;
  }

  private render(content: string): void {
    this.el.nativeElement.innerHTML = content;
    window.MathJax.typesetPromise([this.el.nativeElement]).catch((err: any) => {
      console.error('Error rendering MathJax:', err);
    });
  }
}
