import { Injectable } from '@angular/core';
import { StreamSubject } from '@iese-chatbot/common-utils';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { XhrService } from './xhr.service';

@Injectable({ providedIn: 'root' })
export class XhrMockService implements XhrService {
  private tokensMock: string[] = [
    'This i',
    's a m',
    'ocked re',
    'sponse',
    '\n',
    '\n\\[ VAN = \\su',
    'm_{t=0}^{',
    'n} \\frac{F',
    '_t}{',
    '(1 + r)^t} - ',
    'I_0',
    ' \\]',
  ];
  private emissionInterval = 250; // Tiempo entre cada token (en ms)
  private stop$ = new Subject<void>();

  constructor() {
    console.log('USANDO IMPLEMENTACION MOCK PARA SERVICIO XHR');
  }

  xhrConnection(url: string, message: any, files: File[]): Observable<StreamSubject> {
    const subject = new Subject<StreamSubject>();
    let emittedSoFar = '';

    interval(this.emissionInterval)
      .pipe(takeUntil(this.stop$))
      .subscribe((index) => {
        if (index < this.tokensMock.length) {
          emittedSoFar += this.tokensMock[index];

          // en cada token emitimos lo acumulado hasta aquí (similar al comportamiento real)
          subject.next({ message: emittedSoFar, citations: [] });
        } else {
          subject.complete();
          this.stop$.next();
          this.stop$.complete();
        }
      });

    return subject.asObservable();
  }

  stopStream(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
