import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { IeseLogoUiComponent } from '../icons/iese-logo/iese-logo.ui-component';
import { IeseLogotypeUiComponent } from '../icons/iese-logo/iese-logotype.ui-component';

@Component({
  selector: 'uids-toolbar',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonModule, ToolbarModule, IeseLogoUiComponent, IeseLogotypeUiComponent],
  templateUrl: './toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {}
