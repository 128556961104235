<form class="p-fluid" [formGroup]="form" (ngSubmit)="submitForm()" uidsDragDropFile>

  <div class="form-fieldset" uidsDragDropFile>
    <div class="form-field form-row" [ngClass]="{ 'disabled-element': usingFiles }">
      <label class="form-label" for="subject">Subject</label>
      <input class="p-inputtext form-input" id="subject" type="text" pInputText
             formControlName="subject" (keydown)="handleKeyup($event)" />
    </div>

    <div class="form-field form-row" [ngClass]="{ 'disabled-element': usingContent }">
      <label class="form-label">Upload file(s)</label>
      <icbc-upload-files-button></icbc-upload-files-button>
    </div>
    <icbc-upload-files-list></icbc-upload-files-list>
  </div>

  <div class="form-actions">
    <button class="iese-button" (click)="close()">
      <span>Cancel</span>
    </button>
    <button class="iese-button primary" (click)="clear()">
      <i class="pi pi-delete-left"></i>
      <span>Clear</span>
    </button>
    <button class="iese-button primary" type="submit" [ngClass]="{ 'disabled': !form.valid}">
      <i class="pi pi-send"></i>
      <span>Send</span>
    </button>
  </div>

</form>
