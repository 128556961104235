import { Chat, Role } from '@iese-chatbot/common-utils';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ChatDataActions } from '../actions/chat-data.actions';
import { ChatDataState } from '../states/chat-data.state';

export const compareChatDate = (chat1: Chat, chat2: Chat) => {
  // TODO refactor de objeto Chat para que las fechas puedan ser string o Date
  // noinspection SuspiciousTypeOfGuard
  const date1 = typeof chat1.updatedAt === 'string' ? new Date(chat1.updatedAt) : chat1.updatedAt;
  // noinspection SuspiciousTypeOfGuard
  const date2 = typeof chat2.updatedAt === 'string' ? new Date(chat2.updatedAt) : chat2.updatedAt;
  return date2.getTime() - date1.getTime();
};

export const chatDataAdapter: EntityAdapter<Chat> = createEntityAdapter<Chat>({
  sortComparer: compareChatDate,
});

export const initialChatDataState: ChatDataState = chatDataAdapter.getInitialState({
  active: null,
  renaming: null,
  renamingAuto: null,
  shortcut: null, // TODO refactor para usar null, no undefined
  temporalFiles: [],
  generatingMessage: false,
  persistingHistory: false,
  loading: false,
  uploading: false,
  error: '',
  tempFiles: [],
  tempFileErrors: null,
  messageRequestStartedAt: null,
  showWaitMessage: false,
});

export const chatDataReducer = createReducer(
  initialChatDataState,
  // FETCH
  on(ChatDataActions.fetchChatHistory, (state) => {
    return { ...state, loading: true, error: '' };
  }),
  // FETCH SUCCESS
  on(ChatDataActions.fetchSuccess, (state, { chats }) => {
    return chatDataAdapter.addMany(chats, {
      ...state,
      loading: false,
    });
  }),
  // FETCH ERROR
  on(ChatDataActions.fetchError, (state, { error }) => {
    return { ...state, loading: false, error: error };
  }),

  // PERSIST
  on(ChatDataActions.persistChatHistory, (state) => {
    return { ...state, persistingHistory: true, error: '' };
  }),
  // PERSIST SUCCESS
  on(ChatDataActions.persistChatHistorySuccess, (state) => {
    return { ...state, persistingHistory: false };
  }),
  // PERSIST ERROR
  on(ChatDataActions.persistChatHistoryError, (state, { error }) => {
    return { ...state, persistingHistory: false, error };
  }),

  // SELECT ACTIVE CHAT
  on(ChatDataActions.selectChat, (state, { id }) => {
    return { ...state, active: id, tempFiles: [], tempFileErrors: null, error: '' };
  }),
  // ADD CHAT
  on(ChatDataActions.addChat, (state, { chat }) => {
    return chatDataAdapter.addOne(chat, state);
  }),
  // DELETE CHAT
  on(ChatDataActions.deleteChat, (state, { id }) => {
    return chatDataAdapter.removeOne(id, state);
  }),

  // SELECT SHORTCUT
  on(ChatDataActions.selectShortcut, (state, { shortcut }) => ({ ...state, shortcut })),

  // START RENAMING CHAT
  on(ChatDataActions.startRenamingChat, (state, { chat, auto }) => {
    if (auto) {
      return chatDataAdapter.updateOne(
        {
          id: chat.id,
          changes: { title: 'Generating...' },
        },
        {
          ...state,
          renamingAuto: chat.id,
        },
      );
    } else {
      return {
        ...state,
        renaming: chat.id,
      };
    }
  }),
  // CONFIRM RENAMIMG CHAT
  on(ChatDataActions.confirmRenamingChat, (state, { chat, title }) => {
    return chatDataAdapter.updateOne(
      {
        id: chat.id,
        changes: { title },
      },
      {
        ...state,
        renaming: null,
        renamingAuto: null,
      },
    );
  }),
  // CANCEL RENAMING CHAT
  on(ChatDataActions.cancelRenamingChat, (state) => {
    return {
      ...state,
      renaming: null,
      renamingAuto: null,
    };
  }),

  // ADD MESSAGE TO CHAT
  on(ChatDataActions.addMessage, (state, { chat }) => {
    return chatDataAdapter.updateOne(
      {
        id: chat.id,
        changes: { ...chat },
      },
      state,
    );
  }),

  // START MESSAGE STREAM
  on(ChatDataActions.startMessageStream, (state, { chat }) => {
    const generatingMessage = {
      content: '<div class="label-generating">Generating...</div>',
      role: 'assistant' as Role,
      finished: false,
      fromIESEKnowledge: chat.fromIESEKnowledge,
    };

    return chatDataAdapter.updateOne(
      {
        id: chat.id,
        changes: { messages: [...chat.messages, generatingMessage] },
      },
      {
        ...state,
        generatingMessage: true,
      },
    );
  }),
  // RECEIVE STREAM CHUNK
  on(ChatDataActions.receiveStreamChunk, (state, { chat, chunk, citations }) => {
    const updatedChat = state.entities[chat.id];
    if (!updatedChat) return state;

    const messages = updatedChat.messages;
    if (messages.length === 0) return state;

    const updatedMessages = messages.map((message, index) => {
      if (index === messages.length - 1) {
        return {
          ...message,
          content: chunk,
          citations: citations.slice(),
        };
      }
      return message;
    });

    return chatDataAdapter.updateOne(
      {
        id: updatedChat.id,
        changes: {
          messages: updatedMessages,
        },
      },
      state,
    );
  }),
  // COMPLETE MESSAGE STREAM
  on(ChatDataActions.completeMessageStream, (state) => ({ ...state, generatingMessage: false })),
  // STREAM ERROR
  on(ChatDataActions.streamError, (state, { error }) => ({ ...state, generatingMessage: false, error })),

  // ADD FILELIST
  on(ChatDataActions.addFileList, (state) => ({ ...state, tempFileErrors: null, loading: true })),
  on(ChatDataActions.addFileListSuccess, (state, { tempFiles, tempFileErrors }) => ({
    ...state,
    tempFiles,
    tempFileErrors,
    loading: false,
  })),
  on(ChatDataActions.addFileListFailure, (state, { tempFileErrors }) => ({ ...state, tempFileErrors, loading: false })),
  // REMOVE FILE
  on(ChatDataActions.removeFile, (state, { file }) => ({
    ...state,
    tempFiles: state.tempFiles.filter((f) => f !== file),
  })),
  // CLEAR FILES
  on(ChatDataActions.clearFiles, (state) => ({ ...state, tempFiles: [], fileErrors: null, loading: false })),
  // CLEAR FILES ERRORS
  on(ChatDataActions.clearFilesErrors, (state) => ({ ...state, fileErrors: null })),

  // WAIT MESSAGE
  on(ChatDataActions.messageRequestStarted, (state, { timestamp }) => ({
    ...state,
    messageRequestStartedAt: timestamp,
    showWaitMessage: false,
  })),
  on(ChatDataActions.showWaitMessage, (state) => ({ ...state, showWaitMessage: true })),
  on(ChatDataActions.hideWaitMessage, (state) => ({ ...state, messageRequestStartedAt: null, showWaitMessage: false })),
);
