import { ChatFile } from '../model/chat-file';

export class FileUtils {
  private static readonly ALLOWED_FILE_EXTENSIONS = ['.pdf', '.doc', '.docx', '.ppt', '.pptx'];
  private static readonly ALLOWED_FILE_TYPES = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  ];
  private static readonly MAX_SIZE_PER_FILE_MB = 512;
  private static readonly MAX_FILES_PER_UPLOAD = 10;

  public static getAllowedFileExtensions(): string {
    return this.ALLOWED_FILE_EXTENSIONS.join(',');
  }

  public static async validateAndFilterFiles(files: File[]): Promise<{
    validFiles: File[];
    fileErrors: string[];
    listErrors: string[];
  }> {
    const fileErrors: string[] = [];
    let validFiles: File[] = await this.checkDuplicates(files);
    validFiles = validFiles.filter((file) => {
      const individualErrors = this.handleFileErrors(file);

      if (individualErrors.length > 0) {
        fileErrors.push(...individualErrors);
        return false;
      }

      return true;
    });

    const listErrors = this.handleFilesErrors(validFiles);
    if (listErrors.length > 0) {
      validFiles = validFiles.slice(0, this.MAX_FILES_PER_UPLOAD);
    }

    return {
      validFiles,
      fileErrors,
      listErrors,
    };
  }

  public static async checkDuplicates(files: File[]): Promise<File[]> {
    const uniqueFiles: File[] = [];
    const uniqueHashes = new Set<string>();

    for (const file of files) {
      const fileHash = await this.getFileHash(file);

      if (!uniqueHashes.has(fileHash)) {
        uniqueFiles.push(file);
        uniqueHashes.add(fileHash);
      }
    }

    return uniqueFiles;
  }

  public static checkPreviousFilesDuplicates(tempFiles: File[], chatFiles: ChatFile[]) {
    return tempFiles.filter((tempFile) => {
      return !chatFiles.some((chatFile) => {
        return (
          chatFile.name === tempFile.name &&
          chatFile.size === tempFile.size &&
          chatFile.type === tempFile.type &&
          chatFile.lastModified === tempFile.lastModified
        );
      });
    });
  }

  private static async getFileHash(file: File): Promise<string> {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }

  private static handleFilesErrors(files: File[]): string[] {
    const errors = [];
    if (files.length > this.MAX_FILES_PER_UPLOAD) {
      errors.push(`You can only upload ${this.MAX_FILES_PER_UPLOAD} files at a time.`);
    }
    return errors;
  }

  private static handleFileErrors(file: File | null): string[] {
    if (!file) return [];
    const errors = [];

    if (!this.ALLOWED_FILE_TYPES.includes(file.type)) {
      errors.push(`The "${file.name}" file type is not allowed.`);
    }

    const fileSizeMB = Number((file.size / 1024 / 1024).toFixed(2));
    if (fileSizeMB > this.MAX_SIZE_PER_FILE_MB) {
      errors.push(
        `The "${file.name}" file size (${fileSizeMB}MB) exceeds the maximum size (${this.MAX_SIZE_PER_FILE_MB}MB) and can't be uploaded.`,
      );
    }

    return errors;
  }
}
