import { FormControl, Validators } from '@angular/forms';
import { FileAwareForm } from '../generic-form/file-aware.form';

export const DEFAULT_TRANSLATIONS_CONTENT = '';
export const DEFAULT_TRANSLATIONS_LANGUAGE = 'English';
export const TRANSLATION_LANGUAGES: string[] = ['English', 'French', 'German', 'Portuguese', 'Spanish'];

export class TranslationsForm extends FileAwareForm {
  constructor(fileAwareFields: string[]) {
    super({
      content: new FormControl<string>(DEFAULT_TRANSLATIONS_CONTENT),
      language: new FormControl<string>(DEFAULT_TRANSLATIONS_LANGUAGE, {
        validators: [Validators.required],
      }),
      usingFiles: new FormControl<boolean>(false),
    });

    if (fileAwareFields.length > 0) {
      this.configureConditionalValidators({ fileAwareFields });
    }
  }
}
