import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(sizeInBytes: number): string {
    if (!sizeInBytes || sizeInBytes <= 0) {
      return '0 KB';
    }

    if (sizeInBytes < 1048576) {
      const sizeInKB = Math.round(sizeInBytes / 1024);
      return `${sizeInKB} KB`;
    }

    const sizeInMB = (sizeInBytes / 1048576).toFixed(1);
    return `${sizeInMB} MB`;
  }
}
