import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AuthEffects, provideAuth } from '@iese-chatbot/auth';
import { MonitoringEffects, provideMonitoring } from '@iese-chatbot/azure-insights';
import {
  CHAT_DATA_ACCESS_CONFIG,
  ChatDataEffects,
  ChatUIEffects,
  provideXhrService,
} from '@iese-chatbot/chat-data-access';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../environment/environment';
import { appRoutes } from './app.routes';
import { appStore } from './app.store';
import { authConfig } from './auth.config';

export const appConfig: ApplicationConfig = {
  providers: [
    /* ANGULAR */
    importProvidersFrom(BrowserAnimationsModule),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptorsFromDi()),
    /* STORE NGRX */
    provideStore(appStore),
    provideEffects([ChatDataEffects, ChatUIEffects, AuthEffects, MonitoringEffects]),
    provideRouterStore(),
    !environment.production ? provideStoreDevtools() : [],
    /* CONFIG */
    { provide: CHAT_DATA_ACCESS_CONFIG, useValue: { apiUrl: environment.apiUrl, mock: environment.mock } },
    provideXhrService(environment.mock),
    /* PRIMENG */
    ConfirmationService,
    MessageService,
    /* AZURE */
    provideMonitoring(environment.azure.insights.instrumentationKey),
    provideAuth(authConfig),
  ],
};
