export enum LogLevel {
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  OK = 'OK   ',
  INFO = 'INFO ',
  WARN = 'WARN ',
  MOCK = 'MOCK ',
}

export const LOG_LEVELS = new Map<string, { fgColor: string; bgColor: string }>()
  .set(LogLevel.DEBUG, { fgColor: '#ECEFF4', bgColor: '#B48EAD' })
  .set(LogLevel.OK, { fgColor: '#ECEFF4', bgColor: '#A3BE8C' })
  .set(LogLevel.INFO, { fgColor: '#ECEFF4', bgColor: '#88C0D0' })
  .set(LogLevel.WARN, { fgColor: '#2E3440', bgColor: '#EBCB8B' })
  .set(LogLevel.ERROR, { fgColor: '#ECEFF4', bgColor: '#BF616A' })
  .set(LogLevel.MOCK, { fgColor: '#ECEFF4', bgColor: '#d08770' });
