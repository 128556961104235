import { CommonModule } from '@angular/common';
import { Component, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppState, ChatDataActions } from '@iese-chatbot/chat-data-access';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

@Component({
  selector: 'icbc-generic-form',
  standalone: true,
  imports: [CommonModule],
  template: '',
})
export class GenericFormComponent {
  @Output() public sendData: Subject<string | null> = new Subject<string | null>();
  protected form!: FormGroup;

  constructor(protected store: Store<AppState>) {}

  protected submitForm() {
    if (this.form.valid) {
      this.sendData.next(this.buildPrompt());
      this.sendData.complete();
    }
  }

  protected handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitForm();
    }
  }

  protected buildPrompt(): string {
    return JSON.stringify(this.form.value);
  }

  protected close() {
    this.sendData.next(null);
    this.sendData.complete();
  }

  protected clear() {
    this.form.reset();
    this.store.dispatch(ChatDataActions.clearFiles());
  }
}
