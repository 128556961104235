import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { AppState, ChatDataActions } from '@iese-chatbot/chat-data-access';
import { Store } from '@ngrx/store';
import { DragDropMode } from '../model/enums/drag-drop-mode';

@Directive({
  selector: '[uidsDragDropFile]',
  standalone: true,
})
export class DragDropFileDirective {
  @Input() multiple = true;
  @Input() mode = DragDropMode.AUTO;
  @Input() enableDragDropOverlay = true;
  @Output() filesDropped = new EventEmitter<File[]>();

  private overlayElement!: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private store: Store<AppState>) {
    this.createOverlay();
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showOverlay();
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.hideOverlay();
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.hideOverlay();

    const fileList: FileList | undefined = event.dataTransfer?.files;
    if (fileList && fileList.length > 0) {
      const files = Array.from(fileList);

      if (this.mode === DragDropMode.AUTO) {
        this.store.dispatch(ChatDataActions.addFileList({ tempFiles: files }));
      }
      this.filesDropped.emit(files);
    }
  }

  private createOverlay() {
    this.overlayElement = this.renderer.createElement('div');
    this.renderer.addClass(this.overlayElement, 'drag-drop-overlay');

    this.overlayElement.innerHTML = `
<div class="drag-drop-overlay-content">
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
    <g fill="#FFFFFF" fill-rule="nonzero">
      <path
        d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z"/>
      <path
        d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z"/>
      <path
        d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z"/>
    </g>
  </svg>
  <span>Drop your files here</span>
</div>
    `;

    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
    this.renderer.appendChild(this.elementRef.nativeElement, this.overlayElement);
  }

  private showOverlay() {
    if (this.enableDragDropOverlay) {
      this.renderer.setStyle(this.overlayElement, 'opacity', '1');
    }
  }

  private hideOverlay() {
    if (this.enableDragDropOverlay) {
      this.renderer.setStyle(this.overlayElement, 'opacity', '0');
    }
  }
}
