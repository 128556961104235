import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'textarea[uidsTextareaAutoresize]',
  standalone: true,
})
export class TextareaAutoresizeDirective implements AfterViewInit {
  @Input() baseLineHeight = 24; // Altura base por línea
  @Input() minLines = 7 / 3; // Valor mínimo por defecto
  @Input() maxLines = 14; // Valor máximo por defecto

  constructor(private elementRef: ElementRef) {}

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      this.resize();
    }
  }

  /**
   * Forzar resize de textarea:
   * Este método resetea el textarea a su altura mínima después de que se haya
   * limpiado la pila de ejecución actual. El timeout 0 garantiza que el DOM
   * ha procesado completamente el cambio de contenido.
   */
  public forceResize(): void {
    this.elementRef.nativeElement.style.height = '0';

    setTimeout(() => {
      const minHeight = this.minLines * this.baseLineHeight;
      this.elementRef.nativeElement.style.height = `${minHeight}px`;
    }, 0);
  }

  private resize() {
    this.elementRef.nativeElement.style.height = '0';
    this.elementRef.nativeElement.style.height =
      Math.max(
        this.minLines,
        Math.min(this.maxLines, Math.ceil(this.elementRef.nativeElement.scrollHeight / this.baseLineHeight)),
      ) *
        this.baseLineHeight +
      'px';
  }
}
