import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stream, StreamSubject } from '@iese-chatbot/common-utils';
import { Observable, Subject, takeUntil } from 'rxjs';
import { XhrService } from './xhr.service';

@Injectable({ providedIn: 'root' })
export class XhrRestService implements XhrService {
  private _errorMessage = `
  <div class="chat-message-notification error">
    <i class="pi pi-exclamation-triangle"></i>
    <small>Sorry, there was an error trying to generate the response. Please try again and, if the problem persists, contact the application administrators.</small>
  </div>
  `;
  private _subject = new Subject<StreamSubject>();
  private _stopSubject = new Subject<boolean>();

  constructor(private httpClient: HttpClient) {
    console.log('USANDO IMPLEMENTACION REST PARA SERVICIO XHR');
  }

  public xhrConnection(url: string, message: any, files: File[]): Observable<StreamSubject> {
    const formData = new FormData();
    const messageBlob = new Blob([JSON.stringify(message)], { type: 'application/json' });
    formData.append('message', messageBlob, 'message.json');
    files.forEach((file) => formData.append('files', file, file.name));

    const headers = new HttpHeaders({
      Accept: 'text/event-stream',
    });

    this._subject = new Subject<StreamSubject>();
    this._stopSubject = new Subject<boolean>();

    this.httpClient
      .post(url, formData, {
        headers: headers,
        observe: 'events',
        responseType: 'text',
        reportProgress: true,
      })
      .pipe(takeUntil(this._stopSubject))
      .subscribe({
        next: (event: HttpEvent<string>) => {
          if (event.type === HttpEventType.DownloadProgress) {
            const parsedEvent = event as unknown as {
              type: number;
              loaded: number;
              partialText: string;
            };
            try {
              const streams = (
                parsedEvent.partialText
                  .replace(/data:\s*\[DONE]/, '')
                  .replace(/id:\d*\n/g, '')
                  //.replace(/\n/g, '')
                  .trim()
                  //.split('data:')
                  .match(/data:(.*)}\n\n/g) ?? ([] as string[])
              )
                .filter(Boolean)
                .map((item) => {
                  try {
                    const startIndex = item.indexOf('{');
                    const endIndex = item.lastIndexOf('}') + 1;
                    const json = item.substring(startIndex, endIndex);
                    return JSON.parse(json.replace(/\n/g, '')) as Stream;
                  } catch (jsonexc) {
                    console.error(jsonexc);
                    return null;
                  }
                });

              const message = streams
                .slice()
                .filter((i) => !!i?.choices[0]?.delta?.content)
                .map((i) => i?.choices[0]?.delta?.content)
                .filter((i) => i !== '')
                .join('');

              const citations = streams
                .slice()
                .filter((i) => !!i?.choices[0]?.delta?.context?.citations)
                .flatMap((i) => i?.choices[0].delta?.context?.citations ?? []);

              this._subject.next({ message, citations });
            } catch (exception) {
              /* EXCEPTION */
              console.error(exception);
            }
          } else if (event.type === HttpEventType.Response) {
            this._subject.complete();
          }
        },
        error: (error) => {
          this._subject.next({ message: this._errorMessage });
          this._subject.error(error);
        },
      });

    return this._subject.asObservable();
  }

  public stopStream(): void {
    this._subject.complete();
    this._stopSubject.next(false);
    this._stopSubject.complete();
  }
}
