import { formatDate } from '@angular/common';
import { LOG_LEVELS, LogLevel } from './log-level';

/* eslint-disable @typescript-eslint/no-explicit-any */
export abstract class Logger {
  public static debug(...messages: unknown[]): void {
    Logger.log(LogLevel.DEBUG, messages);
  }

  public static ok(...messages: unknown[]): void {
    Logger.log(LogLevel.OK, messages);
  }

  public static info(...messages: unknown[]): void {
    Logger.log(LogLevel.INFO, messages);
  }

  public static warn(...messages: unknown[]): void {
    Logger.log(LogLevel.WARN, messages);
  }

  public static error(...messages: unknown[]): void {
    Logger.log(LogLevel.ERROR, messages);
  }

  public static mock(...messages: unknown[]): void {
    Logger.log(LogLevel.MOCK, messages);
  }

  private static log(type: LogLevel = LogLevel.DEBUG, messages: unknown[]): void {
    const colors = LOG_LEVELS.get(type);
    const timestamp = formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS', 'en-US');

    console.log(
      `%c ${type} %c ${timestamp} %c `,
      `color: ${colors?.fgColor}; background: ${colors?.bgColor}; padding: 2px 4px; font-weight: bold;`,
      'color: #2E3440; background: #D8DEE9; font-family: monospace; padding: 2px 4px;',
      'color: initial; background: initial;',
      ...messages,
    );
  }
}
