import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export abstract class FormUtils {
  public static configureConditionalValidators(config: {
    form: AbstractControl;
    usingFiles: boolean;
    fileAwareFields: string[];
    validators?: ValidatorFn | ValidatorFn[] | null;
  }) {
    config.fileAwareFields.forEach((fieldName) => {
      const control = config.form.get(fieldName);
      const validators = FormUtils.getValidatorsAsArray();

      if (control) {
        if (!config.usingFiles) {
          control.setValidators([Validators.required, ...validators]);
        } else if (config.validators) {
          control.setValidators(validators);
        } else {
          control.clearValidators();
        }

        control.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private static getValidatorsAsArray(validators?: ValidatorFn | ValidatorFn[] | null): ValidatorFn[] {
    return validators ? (Array.isArray(validators) ? validators : [validators]) : [];
  }
}
