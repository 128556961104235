import { ComponentRef, Directive, Input, OnChanges, SimpleChanges, Type, ViewContainerRef } from '@angular/core';
import { PdfFileUiComponent } from '../components/icons/file-types/pdf-file.ui-component';
import { PowerpointFileUiComponent } from '../components/icons/file-types/powerpoint-file.ui-component';
import { WordFileUiComponent } from '../components/icons/file-types/word-file.ui-component';

type Icon = PdfFileUiComponent | WordFileUiComponent | PowerpointFileUiComponent;

@Directive({
  selector: '[uidsFileTypeIcon]',
  standalone: true,
})
export class FileIconDirective implements OnChanges {
  @Input('uidsFileTypeIcon') mimeType!: string;
  @Input() styleClass?: string;

  private componentRef?: ComponentRef<unknown>;
  private componentMapper: { [key: string]: Type<any> } = {
    'application/pdf': PdfFileUiComponent,
    'application/msword': WordFileUiComponent,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': WordFileUiComponent,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': WordFileUiComponent,
    'application/vnd.ms-word.document.macroEnabled.12': WordFileUiComponent,
    'application/vnd.ms-word.template.macroEnabled.12': WordFileUiComponent,
    'application/vnd.ms-powerpoint': PowerpointFileUiComponent,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': PowerpointFileUiComponent,
    'application/vnd.openxmlformats-officedocument.presentationml.template': PowerpointFileUiComponent,
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': PowerpointFileUiComponent,
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': PowerpointFileUiComponent,
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': PowerpointFileUiComponent,
    'application/vnd.ms-powerpoint.template.macroEnabled.12': PowerpointFileUiComponent,
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': PowerpointFileUiComponent,
  };

  constructor(private vcr: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mimeType']) {
      this.loadIcon();
    }
  }

  private loadIcon() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    const componentType = this.componentMapper[this.mimeType];

    if (componentType) {
      this.componentRef = this.vcr.createComponent(componentType);
      if (this.styleClass) {
        (this.componentRef.instance as Icon).styleClass = this.styleClass;
      }
    } else {
      console.warn('Unsupported MIME type:', this.mimeType);
    }
  }
}
