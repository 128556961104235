import { Pipe, PipeTransform } from '@angular/core';
import { marked, RendererObject, Tokens } from 'marked';

//import { CITATION_METADATA_REMOVE_REGEXP } from './citation-metadata.pipe';

const customrenderer: RendererObject = {
  html(link) {
    const html = link.toString();
    return html
      .replace('<a ', '<a target="_blank" ')
      .replace('</a>', ' <i class="pi pi-external-link ext-link"></i></a>');
  },
};

// Set options
marked.use({
  gfm: true,
  renderer: customrenderer,
  extensions: [
    {
      name: 'code',
      renderer(code) {
        const text = code['text'];
        if (code['lang'] !== 'markdown') {
          const defaultrenderer = new marked.Renderer();
          return defaultrenderer.code(code as Tokens.Code);
        }
        // pull out inner markdown
        return '' + marked(text);
      },
    },
  ],
});

export const REFERENCE_REGEXP = /\[(doc)(\d*)]/g;

@Pipe({
  name: 'mdParse',
  standalone: true,
})
export class MarkdownParsePipe implements PipeTransform {
  transform(value: string): string | Promise<string> {
    if (value && value.length > 0) {
      value = this.removeCitationMetadata(value);
      value = this.parseReferences(value);
      value = this.parseTexFormulae(value);
      // return marked(value, { renderer: this.getCustomRenderer() });
      return marked(value);
    }

    return value;
  }

  private removeCitationMetadata(value: string): string {
    const splittedString = value?.split('#%');
    return splittedString?.length ? splittedString[0] : value;
    //return value.replace(CITATION_METADATA_REMOVE_REGEXP, '').trim();
  }

  private parseReferences(value: string): string {
    value = value.replace(REFERENCE_REGEXP, '<sup>($2)</sup>');

    return value;
  }

  private parseTexFormulae(value: string): string {
    value = value.replace(/\\\(\s(.+?)\s\\\)/gs, '[mjinline]$1[/mjinline]');
    value = value.replace(/\\\[\s(.+?)\s\\]/gs, '[mjdisplay]$1[/mjdisplay]');

    return value;
  }

  // private getCustomRenderer() {
  //   const renderer = new marked.Renderer();
  //   const originalLinkRenderer = renderer.link;

  //   renderer.link = function(link) {
  //     const html = originalLinkRenderer.call(this, link);
  //     return html.replace('<a ', '<a target="_blank" ').replace('</a>', ' <i class="pi pi-external-link ext-link"></i></a>');
  //   };

  //   return renderer;
  // }
}
