import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DragDropFileDirective } from '@iese-chatbot/ui-design-system';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { UploadFilesButtonComponent } from '../../../components/upload-files-button/upload-files-button.component';
import { UploadFilesListComponent } from '../../../components/upload-files-list/upload-files-list.component';
import { FileAwareFormComponent } from '../generic-form/file-aware-form.component';
import { TeachingNoteForm } from './teaching-note.form';

@Component({
  selector: 'icbc-teaching-note',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    UploadFilesButtonComponent,
    UploadFilesListComponent,
    DragDropFileDirective,
  ],
  templateUrl: './teaching-note.component.html',
})
export class TeachingNoteComponent extends FileAwareFormComponent {
  protected override fieldNames = [];
  protected override form: FormGroup = new TeachingNoteForm([]);

  protected override buildPrompt(): string {
    if (this.usingFiles) {
      return `
Write a teaching note of the IESE case ${this.form.value.caseTitle} using the uploaded file as teaching note's template.
The learning objective is ${this.form.value.learningObjective}.
The duration of the session is ${this.form.value.duration} minutes.
The intended audience for the case is ${this.form.value.intendedAudience}.
The tone should be academic.
Also suggest preparation questions about the case.
    `;
    } else {
      return `
Write a teaching note of the IESE case ${this.form.value.caseTitle} based on the IESE teaching note’s template.
The learning objective is ${this.form.value.learningObjective}.
The duration of the session is ${this.form.value.duration} minutes.
The intended audience for the case is ${this.form.value.intendedAudience}.
The tone should be academic.
Also suggest preparation questions about the case.
    `;
    }
  }
}
