<div class="main-chat-box-container">

  <small class="main-chat-box-disclaimer fromIESEKnowledgeWrapper chat-box-message-color"
         *ngIf="tempFiles.length === 0 && chatFiles.length === 0">
    <i class="pi pi-cog"></i> If you don't want to work with IESE content, uncheck
    <p-checkbox styleClass="iese-checkbox" inputId="fromIESEKnowledge" [(ngModel)]="fromIESEKnowledge"
                [binary]="true" />
  </small>

  <icbc-upload-files-list></icbc-upload-files-list>

  <div class="main-chat-box-prompt">

    <!-- BOTÓN SUBIDA DE ARCHIVOS -->
    <icbc-upload-files-button [class.disabled-element]="generatingMessage"></icbc-upload-files-button>

    <!-- ÁREA DE TEXTO -->
    <textarea class="main-chat-box-input" placeholder="Enter your message..." rows="2"
              (keydown)="onKeydown($event)" [(ngModel)]="currentMessage"
              [disabled]="generatingMessage" uidsTextareaAutoresize
              [class.disabled-element]="generatingMessage">
    </textarea>

    <!-- BOTÓN DE MANDAR/PARAR MENSAJE -->
    <button *ngIf="!generatingMessage"
            class="iese-rounded-button primary"
            (click)="sendMessage()">
      <span class="pi pi-send"></span>
    </button>
    <button *ngIf="generatingMessage"
            class="iese-rounded-button primary"
            (click)="stopMessage()">
      <span class="pi pi-stop"></span>
    </button>
  </div>

  <small class="main-chat-box-disclaimer p-2 chat-box-message-color">
    Please keep in mind that ChatGPT can make mistakes, so human supervision is necessary.
  </small>
</div>
