import { CommonModule } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';
import {
  AppState,
  selectActiveChat,
  selectGeneratingMessage,
  selectShowWaitMessage,
} from '@iese-chatbot/chat-data-access';
import { Chat, Citation, MarkdownParsePipe, MathJaxDirective } from '@iese-chatbot/common-utils';
import { IeseLogoUiComponent } from '@iese-chatbot/ui-design-system';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { CitationComponent } from '../../dialogs/citation/citation.component';

@Component({
  selector: 'icbc-main-chat-window',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MarkdownParsePipe,
    IeseLogoUiComponent,
    CitationComponent,
    MessagesModule,
    MathJaxDirective,
  ],
  templateUrl: './chat-messages.component.html',
})
export class ChatMessagesComponent implements AfterViewChecked, AfterViewInit {
  protected chat?: Chat;
  protected generatingMessage = false;
  protected selectedCitation: Citation | undefined;
  protected lastMessageContent = '';
  protected showWaitMessage = false;
  private isUserScrolling = false;
  private userScrollTimeout?: any;

  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
    private store: Store<AppState>,
  ) {
    this.store
      .select(selectActiveChat)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (chat) => {
          this.chat = chat;
        },
      });
    this.store
      .select(selectGeneratingMessage)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (generatingMessage) => {
          this.generatingMessage = generatingMessage;
        },
      });
    this.store
      .select(selectShowWaitMessage)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (showWaitMessage) => {
          this.showWaitMessage = showWaitMessage;
        },
      });
  }

  ngAfterViewChecked(): void {
    if (!this.isUserScrolling && this.lastMessageContent != this.chat?.messages.at(-1)?.content) {
      this.scrollToBottom();
      this.lastMessageContent = this.chat?.messages.at(-1)?.content ?? '';
    }
  }

  ngAfterViewInit(): void {
    const outletElement = document.querySelector('.main-chat-outlet') as HTMLDivElement;
    if (outletElement) {
      outletElement.addEventListener('scroll', () => this.onUserScroll(outletElement));
    }
  }

  protected scrollToBottom(): void {
    const outletElement = document.querySelector('.main-chat-outlet') as HTMLDivElement;
    try {
      outletElement.scrollTop = outletElement?.scrollHeight;
    } catch (error) {
      console.error(error);
    }
  }

  protected selectCitation(citation: Citation | undefined) {
    this.selectedCitation = citation;
    try {
      if (citation && citation.filepath) {
        this.monitoringService.trackEventWithProps('Citation_Click', {
          ChatId: this.chat?.id,
          ContentId: citation.filepath,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  private onUserScroll(outletElement: HTMLDivElement): void {
    const atBottom = outletElement.scrollHeight - outletElement.scrollTop === outletElement.clientHeight;

    if (!atBottom) {
      this.isUserScrolling = true;
      clearTimeout(this.userScrollTimeout);
      this.userScrollTimeout = setTimeout(() => {
        this.isUserScrolling = false;
      }, 10000); // Volver a habilitar el auto-scroll después de 10 segundos de inactividad
    }
  }
}
