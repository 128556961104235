<p-dialog *ngIf="shortcut"
          styleClass="clean-dialog form-dialog"
          [modal]="true"
          [closeOnEscape]="true"
          [dismissableMask]="true"
          [(visible)]="visible"
          (onHide)="closeForm.emit()"
          [style]="{ width: '50rem' }"
          [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">

  <!-- HEADER -->
  <ng-template pTemplate="header">
      <strong>{{ shortcut.title }}</strong>
  </ng-template>

  <!-- CONTENT -->
  <ng-template pTemplate="content" class="shortcut-form-content">
    <span class="shortcut-form-content-message"
          *ngIf="shortcut.type !== ShortcutType.TRANSLATIONS_TEXT && shortcut.type !== ShortcutType.TRANSLATIONS_FILES">
      <i class="pi pi-asterisk primary"></i> Please, ensure that all fields are completed in <strong>English</strong>
    </span>
    <div [ngSwitch]="shortcut.type">
      <icbc-teaching-note (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.TEACHING_NOTE">
      </icbc-teaching-note>
      <!-- TBD Define new component -->
      <icbc-academic-content (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.TECHNICAL_NOTE">
      </icbc-academic-content>
      <!-- TBD Define new component -->
      <icbc-academic-content (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.ARTICLE">
      </icbc-academic-content>

      <icbc-syllabus (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.SYLLABUS">
      </icbc-syllabus>

      <!-- split into two components -->
      <icbc-abstract-keywords (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.ABSTRACT_KEYWORDS_TEXT">
      </icbc-abstract-keywords>
      <icbc-abstract-keywords (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.ABSTRACT_KEYWORDS_FILES">
      </icbc-abstract-keywords>

      <!-- split into two components -->
      <icbc-translations (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.TRANSLATIONS_TEXT">
      </icbc-translations>
      <icbc-translations (sendData)="sendData($event)" *ngSwitchCase="ShortcutType.TRANSLATIONS_FILES">
      </icbc-translations>
    </div>
  </ng-template>

</p-dialog>
