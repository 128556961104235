// ------------- DEV -------------
// export const environment = {
//   production: false,
//   apiUrl: 'https://app-frc-chatbotllmsrv-dev.azurewebsites.net',
//   mock: false,
//   azure: {
//     insights: {
//       instrumentationKey: '9306f2cb-4b3d-47cd-90e5-b2fc3e594865',
//     },
//     msal: {
//       clientId: '0c0b4e88-1183-44ac-8e88-39ab6dc89e04',
//       authority: 'https://login.microsoftonline.com/ed0cd196-c46d-43d9-813e-500e8c413eda',
//       protectedResourceMap: new Map([
//         /* DEV */
//         ['https://app-frc-chatbotllmsrv-dev.azurewebsites.net/*', [`0c0b4e88-1183-44ac-8e88-39ab6dc89e04/.default`]],
//       ]),
//     },
//   },
// };
// ------------- PRE -------------
export const environment = {
  production: false,
  apiUrl: 'https://app-swe-chatbotllmsrv-pre.azurewebsites.net',
  mock: false,
  azure: {
    insights: {
      instrumentationKey: '9306f2cb-4b3d-47cd-90e5-b2fc3e594865',
    },
    msal: {
      clientId: 'eb954593-c7c1-4469-9059-92d7a72d1eb3',
      authority: 'https://login.microsoftonline.com/ed0cd196-c46d-43d9-813e-500e8c413eda',
      protectedResourceMap: new Map([
        /* PRO */
        ['https://app-swe-chatbotllmsrv-pre.azurewebsites.net/*', [`eb954593-c7c1-4469-9059-92d7a72d1eb3/.default`]],
      ]),
    },
  },
};
// ------------- PRO -------------
// export const environment = {
//   production: false,
//   apiUrl: 'https://app-swe-chatbotllmsrv-pro-a4bfbphgfagsf8bn.swedencentral-01.azurewebsites.net',
//   mock: true,
//   azure: {
//     insights: {
//       instrumentationKey: '9306f2cb-4b3d-47cd-90e5-b2fc3e594865',
//     },
//     msal: {
//       clientId: '32bbd97a-4edc-4849-8154-d831ab716fb3',
//       authority: 'https://login.microsoftonline.com/ed0cd196-c46d-43d9-813e-500e8c413eda',
//       protectedResourceMap: new Map([
//         /* PRO */
//         ['https://app-swe-chatbotllmsrv-pro-a4bfbphgfagsf8bn.swedencentral-01.azurewebsites.net/*', [`32bbd97a-4edc-4849-8154-d831ab716fb3/.default`]],
//       ]),
//     },
//   },
// };
