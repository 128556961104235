import { Inject, Injectable } from '@angular/core';
import { AZURE_INSIGHTS_CONFIG, AzureInsightsConfig } from '../config';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { MonitoringService } from './monitoring.service';
import { AccountInfo } from '@azure/msal-browser';

@Injectable()
export class AzureInsightsService implements MonitoringService {
  private _angularPlugin = new AngularPlugin();
  private _azureInsights = new ApplicationInsights({
    config: {
      instrumentationKey: this.config.instrumentationKey,
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      disableFetchTracking: false,
      disableXhr: false,
      enableCorsCorrelation: true,
      correlationHeaderDomains: [
        'iese.edu',
        'azurewebsites.net',
      ],
      extensions: [this._angularPlugin],
      extensionConfig: {
        [this._angularPlugin.identifier]: {
          instrumentationKey: this.config.instrumentationKey,
          router: this.router,
        },
      },
    },
  });

  constructor(
    private router: Router,
    @Inject(AZURE_INSIGHTS_CONFIG) private config: AzureInsightsConfig,
  ) {
    this._azureInsights.loadAppInsights();
    this._azureInsights.context.session.id = crypto.randomUUID();
  }

  setContext(user: AccountInfo | null): void {
    if (user) {
      this._azureInsights.setAuthenticatedUserContext(user.username, user.localAccountId);
      this._azureInsights.context.user.id = user.username;
    }
    else
      this._azureInsights.clearAuthenticatedUserContext();
  }

  public trackEvent(name: string): void {
    this._azureInsights.trackEvent({ name });
  }

  public trackEventWithProps(name: string, properties: any): void {
    this._azureInsights.trackEvent({ name, properties });
  }

  public trackTrace(message: string): void {
    this._azureInsights.trackTrace({ message });
  }

}
