import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChatDataActions, selectActiveShortcut } from '@iese-chatbot/chat-data-access';
import { Shortcut, ShortcutCard } from '@iese-chatbot/common-utils';
import { ArrowRightUiComponent } from '@iese-chatbot/ui-design-system';
import { Store } from '@ngrx/store';
import { BdcWalkModule, BdcWalkService } from 'bdc-walkthrough';
import { shortcutCards } from '../../data/shortcut-cards.data';
import { ShortcutFormComponent } from '../../dialogs/shortcut-form/shortcut-form.component';
import { ShortcutHelpComponent } from '../../dialogs/shortcut-help/shortcut-help.component';

@Component({
  selector: 'icbc-shortcut',
  standalone: true,
  imports: [CommonModule, ArrowRightUiComponent, BdcWalkModule, ShortcutFormComponent, ShortcutHelpComponent],
  templateUrl: './shortcut.component.html',
})
export class ShortcutComponent {
  protected readonly visibleShortcutCards = shortcutCards.filter((i) => i.visible);
  protected selectedSuggestion: Shortcut | null = null;
  protected formIsVisible = false;
  protected helpIsVisible = false;

  constructor(private store: Store, protected bdcWalkService: BdcWalkService) {
    this.store
      .select(selectActiveShortcut)
      .pipe(takeUntilDestroyed())
      .subscribe((shortcut) => {
        this.selectedSuggestion = shortcut;
      });
  }

  // TODO refactor código duplicado
  endOnboardingTour() {
    this.bdcWalkService.setTaskCompleted('dialogStep1', true);
    this.bdcWalkService.setTaskCompleted('dialogStep2', true);
    this.bdcWalkService.setTaskCompleted('popupStep3', true);
    this.bdcWalkService.setTaskCompleted('popupStep4', true);
    this.bdcWalkService.setTaskCompleted('popupStep5', true);
    // this.bdcWalkService.setTaskCompleted('popupStep6', true);
    // this.bdcWalkService.setTaskCompleted('popupStep7', true);
    this.bdcWalkService.setTaskCompleted('dialogStep8', true);
    this.bdcWalkService.setTaskCompleted('popupStep9', true);
    // this.bdcWalkService.setTaskCompleted('popupStep10', true);
    this.bdcWalkService.setTaskCompleted('popupStep11', true);
    this.bdcWalkService.setTaskCompleted('dialogStep12', true);
    this.bdcWalkService.setTaskCompleted('dialogStep13', true);
  }

  protected closeForm() {
    this.store.dispatch(ChatDataActions.selectShortcut({ shortcut: null, mode: null }));
    this.formIsVisible = false;
  }

  protected closeHelp() {
    this.store.dispatch(ChatDataActions.selectShortcut({ shortcut: null, mode: null }));
    this.helpIsVisible = false;
  }

  // TODO refactor para sacar el monitoring service del componente
  protected openShortcutForm(shortcut: Shortcut) {
    this.store.dispatch(ChatDataActions.selectShortcut({ shortcut, mode: 'form' }));
    this.formIsVisible = true;
  }

  protected openShortcutHelp(shortcutCard: ShortcutCard) {
    // this.store.dispatch(ChatDataActions.selectShortcut({ shortcut, mode: 'help' }));
    this.helpIsVisible = true;
  }
}
