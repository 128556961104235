<a [routerLink]="['', 'chat', chatItem.id]"
   *ngIf="chatItem && (renaming$ | async) !== chatItem.id"
   routerLinkActive="active"
   [routerLinkActiveOptions]="{exact: true}"
   (click)="selectChat(chatItem)"
   class="chat-history-item">

  <!-- TODO refactor para evitar "blinks" mientras se está generando mensaje/titulo -->
  <span *ngIf="(renaming$ | async) !== chatItem.id"
        class="chat-history-item-label"
        [ngClass]="{ 'label-generating': (renamingAuto$ | async) === chatItem.id }"
        [pTooltip]="chatItem.title">
    {{ chatItem.title }}
  </span>
  <input *ngIf="(renaming$ | async) === chatItem.id" #newTitleInput
         (click)="bypassEvents($event)"
         class="chat-history-item-input"
         type="text" [(ngModel)]="newTitle"
  />

  <div class="chat-history-item-actions" *ngIf="(renaming$ | async) !== chatItem.id">
    <button class="iese-rounded-button" (click)="startRenaming($event)" pTooltip="Rename">
      <i class="pi pi-pencil"></i>
    </button>
    <button class="iese-rounded-button" (click)="confirmDelete($event)" pTooltip="Delete">
      <i class="pi pi-trash"></i>
    </button>
  </div>

  <div class="chat-history-item-actions" *ngIf="(renaming$ | async) === chatItem.id">
    <button class="iese-rounded-button" (click)="cancelRenaming($event)" pTooltip="Cancel">
      <i class="pi pi-times"></i>
    </button>
    <button class="iese-rounded-button" (click)="confirmRenaming($event)" pTooltip="Confirm">
      <i class="pi pi-check"></i>
    </button>
  </div>

</a>

<span *ngIf="chatItem && (renaming$ | async) === chatItem.id"
      class="chat-history-item chat-history-item-renaming active">

  <!-- TODO refactor para evitar "blinks" mientras se está generando mensaje/titulo -->
  <span *ngIf="(renaming$ | async) !== chatItem.id"
        class="chat-history-item-label"
        [ngClass]="{ 'label-generating': (renamingAuto$ | async) === chatItem.id }"
        [pTooltip]="chatItem.title">
    {{ chatItem.title }}
  </span>
  <input *ngIf="(renaming$ | async) === chatItem.id" #newTitleInput
         (click)="bypassEvents($event)"
         class="chat-history-item-input"
         type="text" [(ngModel)]="newTitle"
  />

  <div class="chat-history-item-actions" *ngIf="(renaming$ | async) !== chatItem.id">
    <button class="iese-rounded-button">
      <i class="pi pi-pencil" (click)="startRenaming($event)" pTooltip="Rename"></i>
    </button>
    <button class="iese-rounded-button">
      <i class="pi pi-trash" (click)="confirmDelete($event)" pTooltip="Delete"></i>
    </button>
  </div>

  <div class="chat-history-item-actions" *ngIf="(renaming$ | async) === chatItem.id">
    <button class="iese-rounded-button">
      <i class="pi pi-times" (click)="cancelRenaming($event)" pTooltip="Cancel"></i>
    </button>
    <button class="iese-rounded-button">
      <i class="pi pi-check" (click)="confirmRenaming($event)" pTooltip="Confirm"></i>
    </button>
  </div>

</span>
