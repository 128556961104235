@if ((tempFiles && tempFiles.length > 0) || (chatFiles && chatFiles.length > 0)) {
  <div class="file-list">
    @if (chatFiles && chatFiles.length > 0) {
      @for (file of chatFiles; track $index) {
        <p-chip styleClass="chat-file-chip"
                (mouseenter)="showFileInfo(file, fileInfoOverlay, $event)"
                (mousemove)="refreshOverlay(file, fileInfoOverlay, $event)"
                (mouseleave)="hideFileInfo(fileInfoOverlay)">
          <div [uidsFileTypeIcon]="file.type" styleClass="file-icon-small"></div>
          <span class="file-chip-filename">{{ file.name }}</span>
          <p-overlayPanel styleClass="iese-overlay-panel" #fileInfoOverlay>
            @if (selectedFile === file) {
              <div class="flex align-items-center gap-3">
                <div [uidsFileTypeIcon]="file.type" styleClass="file-icon-medium"></div>
                <div>
                  <div class="font-bold">{{ file.name }}</div>
                  <div class="text-sm">
                    Type: {{ file.type | fileMimetype }}
                  </div>
                  <div class="text-sm">
                    Size: {{ file.size | fileSize }}
                  </div>
                  <div class="text-sm">
                    Last modified: {{ file.lastModified | date: 'medium' }}
                  </div>
                </div>
              </div>
            }
          </p-overlayPanel>
        </p-chip>
      }
    }
    @if (tempFiles && tempFiles.length > 0) {
      @for (file of tempFiles; track $index) {
        <p-chip styleClass="file-chip"
                (mouseenter)="showFileInfo(file, fileInfoOverlay, $event)"
                (mousemove)="refreshOverlay(file, fileInfoOverlay, $event)"
                (mouseleave)="hideFileInfo(fileInfoOverlay)">
          <div [uidsFileTypeIcon]="file.type" styleClass="file-icon-small"></div>
          <span class="file-chip-filename">{{ file.name }}</span>
          <span class="iese-rounded-button primary small" (click)="removeFile(file, fileInfoOverlay)">
        <i class="pi pi-times"></i>
      </span>
          <p-overlayPanel styleClass="iese-overlay-panel" #fileInfoOverlay>
            @if (selectedFile === file) {
              <div class="flex align-items-center gap-3">
                <div [uidsFileTypeIcon]="file.type" styleClass="file-icon-medium"></div>
                <div>
                  <div class="font-bold">{{ file.name }}</div>
                  <div class="text-sm">
                    Type: {{ file.type | fileMimetype }}
                  </div>
                  <div class="text-sm">
                    Size: {{ file.size | fileSize }}
                  </div>
                  <div class="text-sm">
                    Last modified: {{ file.lastModified | date: 'medium' }}
                  </div>
                </div>
              </div>
            }
          </p-overlayPanel>
        </p-chip>
      }
    }
  </div>
}
