<div id="iese-layout">

  <div id="iese-layout-container">
    <icbc-chat-errors></icbc-chat-errors>

    <uids-toolbar>

      <ng-container icbc-toolbar-start></ng-container>

      <ng-container icbc-toolbar-center>
        <icbc-toolbar-chat-title></icbc-toolbar-chat-title>
      </ng-container>

      <ng-container icbc-toolbar-end>
        <icbc-toolbar-user-profile></icbc-toolbar-user-profile>
      </ng-container>

    </uids-toolbar>

    <div id="iese-layout-main-content">
      <button id="iese-sidebar-toggle" class="iese-rounded-button iese-sidebar-toggle"
              (click)="toggleSidebar()" [ngClass]="{ 'collapsed' : (sidebarVisible$ | async) === false }">
        <svg sidebar></svg>
      </button>
      <uids-sidebar>
        <ng-container icbc-sidebar-header>
          <icbc-sidebar-new-chat></icbc-sidebar-new-chat>
        </ng-container>

        <ng-container icbc-sidebar-content>
          <icbc-sidebar-chat-history-list [bdcWalkTriggerFor]="popupStep5"></icbc-sidebar-chat-history-list>
        </ng-container>

        <ng-container icbc-sidebar-footer>
          <icbc-sidebar-release-notes [bdcWalkTriggerFor]="popupStep11"></icbc-sidebar-release-notes>
        </ng-container>

      </uids-sidebar>

      <div class="main-chat-container" uidsDragDropFile [enableDragDropOverlay]="enableDragDropOverlay">
        <div class="main-chat-outlet">
          <router-outlet></router-outlet>
        </div>
        <icbc-main-chat-box [bdcWalkTriggerFor]="popupStep9"></icbc-main-chat-box>
      </div>
    </div>

  </div>

</div>

<p-confirmDialog key="confirmDeleteChat" />

<bdc-walk-dialog #dialogStep1 name="dialogStep1" width="650px"
                 [mustCompleted]="{showTour: true}">
<div class="mat-dialog-iese">
  <h1 class="header">Welcome to MyAI</h1>
  Welcome to MyAI, the new AI-powered assistant designed to enhance your productivity and decision-making process.
  <br/><br/>
  Let's get started!

  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
  <div class="buttons">
    <div class="sideNote">1/10</div>
    <button class="iese-button primary" (click)="dialogStep1.close()" mat-raised-button color="primary">Next</button>
  </div>
</div>
</bdc-walk-dialog>

<bdc-walk-dialog #dialogStep2 name="dialogStep2" width="650px"
                 [mustCompleted]="{dialogStep1: true}">
<div class="mat-dialog-iese">
  <h1 class="header">How do I get value from MyAI</h1>
  Familiarize yourself with key use cases of MyAI. <br/>
  <br/>1. Create a new teaching content
  <br/>2. Create a course/program syllabus proposal
  <br/>3. Abstract creation and keyword extraction from content
  <br/>4. Translate this content into another language
  <br/>5. Create a new teaching note

  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('dialogStep1', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
  <div class="buttons">
    <div class="sideNote">2/10</div>
    <button class="iese-button primary" (click)="dialogStep2.close()" mat-raised-button color="primary">Next</button>
  </div>
</div>
</bdc-walk-dialog>

<bdc-walk-popup sideNoteText="5/10" [showCloseButton]="false" #popupStep5 name="popupStep5" header="Conversation history" [horizontal]="true" xPosition="after" [showButton]="true" buttonText="Next" [mustCompleted]="{popupStep4: true}" class="warning">
  <div>
    Review your previous chats by selecting them from the list on the left. This feature helps you keep track of past conversations and follow up on earlier queries.
  </div>
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('popupStep4', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
</bdc-walk-popup>

<bdc-walk-dialog #dialogStep8 name="dialogStep8" width="650px"
                 [mustCompleted]="{popupStep5: true}">
<div class="mat-dialog-iese">
  <h1 class="header">Get an expert</h1>
  To learn more about <a target="_blank" href="https://ieseinternal.zendesk.com/hc/en-us/articles/26433788958609-Get-an-expert-in-MyAI">functionalities</a>.
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('popupStep5', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
  <div class="buttons">
    <div class="sideNote">6/10</div>
    <button class="iese-button primary" (click)="dialogStep8.close()" mat-raised-button color="primary">Next</button>
  </div>
</div>
</bdc-walk-dialog>

<bdc-walk-popup sideNoteText="7/10" [showCloseButton]="false" #popupStep9 name="popupStep9" header="Start using MyAI" xPosition="after" yPosition="above" [showButton]="true" buttonText="Next" [mustCompleted]="{dialogStep8: true}" class="warning">
  <div>
    Enter your request or query in the text box below and hit 'Enter' to submit MyAI is here to help.
  </div>
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('dialogStep8', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
</bdc-walk-popup>

<bdc-walk-popup sideNoteText="8/10" [showCloseButton]="false" #popupStep11 name="popupStep11" header="Support center" xPosition="after" [offsetX]="-25" [showButton]="true" buttonText="Next" [mustCompleted]="{popupStep9: true}" class="warning">
  <div>
    For any assistance or questions, visit our support section or contact our helpdesk at <a (click)="sendSupportEventAndMailTo()" href="javascript:void(0)">support email</a>.
  </div>
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('popupStep9', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
</bdc-walk-popup>

<bdc-walk-dialog #dialogStep12 name="dialogStep12" width="650px"
                 [mustCompleted]="{popupStep11: true}">
<div class="mat-dialog-iese">
  <h1 class="header">Give us feedback</h1>
  Your feedback is invaluable to us.
  <br/>Please share your thoughts through the icon <img src="/images/hotjarIcon.png" alt="Hotjar" style="vertical-align: text-top;" />

  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('popupStep11', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
  <div class="buttons">
    <div class="sideNote">9/10</div>
    <button class="iese-button primary" (click)="dialogStep12.close()" mat-raised-button color="primary">Next</button>
  </div>
</div>
</bdc-walk-dialog>

<bdc-walk-dialog #dialogStep13 name="dialogStep13" width="650px"
                 [mustCompleted]="{dialogStep12: true}">
<div class="mat-dialog-iese">
  <h1 class="header">Thank you</h1>
  Thank you for taking the tour! We hope this onboarding guide help you understand the core functionalities of MyAI and how to make the most of it for your tasks. If you need further assistance, feel free to reach out through the support section.

  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('dialogStep12', false)" mat-raised-button color="primary">Previous</button>
  </div>
  <div class="buttons">
    <div class="sideNote">10/10</div>
    <button class="iese-button primary" (click)="dialogStep13.close();" mat-raised-button color="primary">Done</button>
  </div>
</div>
</bdc-walk-dialog>
