export enum ShortcutType {
  TEACHING_NOTE = 'teaching-note',
  TECHNICAL_NOTE = 'technical-note',
  ARTICLE = 'article',
  SYLLABUS = 'syllabus',
  ABSTRACT_KEYWORDS_TEXT = 'abstract-keywords-text',
  ABSTRACT_KEYWORDS_FILES = 'abstract-keywords-files',
  TRANSLATIONS_TEXT = 'translations-text',
  TRANSLATIONS_FILES = 'translations-files',
}
