<form class="p-fluid" [formGroup]="form" (ngSubmit)="submitForm()">

  <div class="form-fieldset" uidsDragDropFile>
    <div class="form-field form-row">
      <label class="form-label">Language</label>
      <p-dropdown inputId="language" [options]="languages" formControlName="language" placeholder="Select a language" />
    </div>

    <div class="form-field form-row" [ngClass]="{ 'disabled-element': usingFiles }">
      <label class="form-label" for="content">Content</label>
      <textarea class="p-inputtext form-input" id="content" pInputText formControlName="content"
                uidsTextareaAutoresize rows="1" [minLines]="7" [maxLines]="15">
    </textarea>
    </div>

    <div class="form-field form-row" [ngClass]="{ 'disabled-element': usingContent }">
      <label class="form-label">Upload file(s)</label>
      <icbc-upload-files-button></icbc-upload-files-button>
    </div>
    <icbc-upload-files-list></icbc-upload-files-list>
  </div>

  <div class="form-actions">
    <button class="iese-button" (click)="close()">
      <span>Cancel</span>
    </button>
    <button class="iese-button primary" (click)="clear()">
      <i class="pi pi-delete-left"></i>
      <span>Clear</span>
    </button>
    <button class="iese-button primary" type="submit" [ngClass]="{ 'disabled': !form.valid}">
      <i class="pi pi-send"></i>
      <span>Send</span>
    </button>
  </div>

</form>
