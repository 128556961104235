import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { GenericFormComponent } from '../generic-form/generic-form.component';
import { SyllabusForm } from './syllabus.form';

@Component({
  selector: 'icbc-syllabus',
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextModule, ReactiveFormsModule],
  templateUrl: './syllabus.component.html',
})
export class SyllabusComponent extends GenericFormComponent {
  protected override form: FormGroup = new SyllabusForm();

  protected override buildPrompt(): string {
    return `
Create a syllabus for ${this.form.value.intendedAudience} about ${this.form.value.courseTopic} in ${this.form.value.amountSessions} sessions.
Include for each session academic material that you have indexed.
Focus the syllabus on these learning objectives: ${this.form.value.learningObjectives}
    `;
  }
}
