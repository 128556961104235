import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShortcutCard } from '@iese-chatbot/common-utils';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'icbc-shortcut-help',
  standalone: true,
  imports: [CommonModule, DialogModule],
  templateUrl: './shortcut-help.component.html',
})
export class ShortcutHelpComponent {
  @Input() public shortcutCard: ShortcutCard | undefined;
  // TODO refactor para abstraer lógica común DIALOG-COMMON
  @Output() public closeHelp: EventEmitter<void> = new EventEmitter<void>();

  protected visible = true;
}
