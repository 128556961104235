import { Chat, Citation, Shortcut } from '@iese-chatbot/common-utils';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

// TODO refactor de props para, en la medida de lo posible, usar `id: string` en lugar de `chat: Chat` como prop y
//  buscar dentro de las actions si hiciera falta. Hay que revisar también los effects para ver en qué casos esto es
//  viable y en cuáles no
export const ChatDataActions = createActionGroup({
  source: '[CHAT DATA]',
  events: {
    'Fetch Chat History': emptyProps(),
    'Fetch Success': props<{ chats: Chat[] }>(),
    'Fetch Error': props<{ error: string }>(),

    'Persist Chat History': props<{ chat: Chat }>(),
    'Persist Chat History Success': emptyProps(),
    'Persist Chat History Error': props<{ error: string }>(),

    'Select Chat': props<{ id: string | null }>(),
    'Add Chat': props<{ chat: Chat; mock: boolean; shortcut: Shortcut | null; files: File[] }>(),
    'Delete Chat': props<{ id: string }>(),

    'Select Shortcut': props<{ shortcut: Shortcut | null; mode: 'form' | 'help' | null }>(),

    'Start Renaming Chat': props<{ chat: Chat; auto: boolean }>(),
    'Confirm Renaming Chat': props<{ chat: Chat; title: string }>(),
    'Cancel Renaming Chat': emptyProps(),

    'Add Message': props<{ chat: Chat; mock: boolean; files: File[] }>(),

    'Start Message Stream': props<{ chat: Chat; mock: boolean; shortcut: Shortcut | null; files: File[] }>(),
    'Receive Stream Chunk': props<{ chat: Chat; chunk: string; citations: Citation[] }>(),
    'Complete Message Stream': props<{ chat: Chat; manually: boolean }>(),
    'Stream Error': props<{ error: string }>(),

    'Add File List': props<{ tempFiles: File[] }>(),
    'Add File List Success': props<{ tempFiles: File[]; tempFileErrors: string[] }>(),
    'Add File List Failure': props<{ tempFileErrors: string[] }>(),
    'Remove File': props<{ file: File }>(),
    'Clear Files': emptyProps(),
    'Clear Files Errors': emptyProps(),

    'Message Request Started': props<{ timestamp: number }>(),
    'Show Wait Message': emptyProps(),
    'Hide Wait Message': emptyProps(),
  },
});
