<div class="shortcut-container">
  <div class="shortcut-container-title">
    <div class="title">Welcome to My AI</div>
    <div class="subtitle">
      the new AI-powered assistant designed to enhance your productivity and
      decision-making process
    </div>
  </div>

  <div class="shortcut-grid-wrapper">
    <div class="shortcut-grid">
      <div *ngFor="let card of visibleShortcutCards" class="shortcut" [bdcWalkTriggerFor]="popupStep3">
        <div class="shortcut-header">
          <!--<i class="{{ card.icon }}"></i>-->
          <span class="shortcut-header-label">{{ card.title }}</span>
        </div>
        <div class="filler"></div>
        <div class="shortcut-content">
          <div class="shortcut-content-links">
            <button *ngFor="let shortcut of card.shortcuts" class="access-link" (click)="openShortcutForm(shortcut)">
              <span>{{ shortcut.linkTitle }}</span>
              <svg arrowRight></svg>
            </button>
          </div>
          <div class="filler"></div>
          <div class="shortcut-content-help">
            <button class="iese-rounded-button outlined" (click)="openShortcutHelp(card)">
              <i class="pi pi-question"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<icbc-shortcut-form *ngIf="selectedSuggestion && formIsVisible" [shortcut]="selectedSuggestion"
                    (closeForm)="closeForm()">
</icbc-shortcut-form>

<!--<icbc-shortcut-help *ngIf="selectedSuggestion && helpIsVisible" [shortcutCard]="selectedSuggestion"
                    (closeHelp)="closeHelp()">
</icbc-shortcut-help>-->

<bdc-walk-popup sideNoteText="3/10" [showCloseButton]="false" #popupStep3 name="popupStep3" header="Shortcuts"
                xPosition="before" yPosition="below" [showButton]="true" buttonText="Next"
                [mustCompleted]="{ dialogStep2: true }"
                class="warning">
  <div>
    Quickly access common taks with these shortcuts. Simply click on the
    shortcut buttons to perform specific actions instantly. These shortcuts are
    designed to save you time.
  </div>
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('dialogStep2', false)">
      Previous
    </button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()">
      Skip
    </button>
  </div>
</bdc-walk-popup>
