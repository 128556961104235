import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AppState } from '@iese-chatbot/chat-data-access';
import { DragDropFileDirective, TextareaAutoresizeDirective } from '@iese-chatbot/ui-design-system';
import { Store } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { UploadFilesButtonComponent } from '../../../components/upload-files-button/upload-files-button.component';
import { UploadFilesListComponent } from '../../../components/upload-files-list/upload-files-list.component';
import { FileAwareFormComponent } from '../generic-form/file-aware-form.component';
import { TRANSLATION_LANGUAGES, TranslationsForm } from './translations.form';

@Component({
  selector: 'icbc-translations',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    DropdownModule,
    TextareaAutoresizeDirective,
    DragDropFileDirective,
    UploadFilesListComponent,
    UploadFilesButtonComponent,
  ],
  templateUrl: './translations.component.html',
})
export class TranslationsComponent extends FileAwareFormComponent {
  protected override fieldNames = ['content'];
  protected override form: FormGroup = new TranslationsForm(this.fieldNames);
  protected languages = TRANSLATION_LANGUAGES;

  constructor(protected override store: Store<AppState>) {
    super(store);
  }

  protected override buildPrompt(): string {
    if (this.usingFiles) {
      return `Translate to ${this.form.value.language} language the content of the attached file(s).`;
    } else {
      return `Translate to ${this.form.value.language} language the following content: ${this.form.value.content}.`;
    }
  }
}
