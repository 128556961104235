import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CHAT_DATA_ACCESS_CONFIG,
  ChatDataAccessConfig,
  ChatDataActions,
  selectActiveChat,
  selectActiveChatFiles,
  selectGeneratingMessage,
  selectTempFiles,
} from '@iese-chatbot/chat-data-access';
import { Chat, ChatFile, getChatFileFromFile, Role } from '@iese-chatbot/common-utils';
import { TextareaAutoresizeDirective } from '@iese-chatbot/ui-design-system';
import { Store } from '@ngrx/store';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { UploadFilesButtonComponent } from '../upload-files-button/upload-files-button.component';
import { UploadFilesListComponent } from '../upload-files-list/upload-files-list.component';

@Component({
  selector: 'icbc-main-chat-box',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
    TooltipModule,
    TextareaAutoresizeDirective,
    UploadFilesButtonComponent,
    UploadFilesListComponent,
  ],
  templateUrl: './main-chat-box.component.html',
})
export class MainChatBoxComponent implements OnInit, OnDestroy {
  @ViewChild(TextareaAutoresizeDirective)
  protected textareaResize!: TextareaAutoresizeDirective;

  protected mock = false;
  protected fromIESEKnowledge = true;
  protected currentMessage = '';
  protected activeChat: Chat | undefined;
  protected generatingMessage = false;
  protected tempFiles: File[] = [];
  protected chatFiles: ChatFile[] = [];
  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(CHAT_DATA_ACCESS_CONFIG) private config: ChatDataAccessConfig,
    private store: Store,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.mock = this.config.mock;
    this.store
      .select(selectActiveChat)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe({
        next: (activeChat) => {
          this.activeChat = activeChat;
          this.fromIESEKnowledge = activeChat?.messages[activeChat?.messages.length - 1].fromIESEKnowledge ?? true;
        },
      });
    this.store
      .select(selectGeneratingMessage)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe({
        next: (generatingMessage) => {
          this.generatingMessage = generatingMessage;
        },
      });
    this.store
      .select(selectTempFiles)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((files) => {
        this.tempFiles = files;
      });
    this.store
      .select(selectActiveChatFiles)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((chatFiles) => {
        this.chatFiles = chatFiles;
      });
  }

  protected sendMessage() {
    // TODO desacoplar esta lógica correctamente con NGRX
    let message = this.currentMessage.trim();
    const stripEmptyLines = message.replace(/^\r\n$|^\r$|^\n$/g, '');
    if (stripEmptyLines.length === 0) {
      return;
    }
    message = message.replace(/\r\n|\r|\n/g, '<br />');

    if (message.length === 0) {
      return;
    }

    // TODO añadir metadatos de archivos cuando se puedan subir archivos a lo largo de la conversación y no solamente
    //  al principio.
    if (this.activeChat) {
      const chat = {
        ...this.activeChat,
        fromIESEKnowledge: this.fromIESEKnowledge,
        messages: [
          ...this.activeChat.messages,
          {
            role: 'user' as Role,
            content: message,
            finished: true,
            fromIESEKnowledge: this.fromIESEKnowledge,
          },
        ],
        files: [...(this.activeChat.files ?? []), ...this.tempFiles.map(getChatFileFromFile)],
      };
      this.store.dispatch(ChatDataActions.addMessage({ chat, mock: this.mock, files: this.tempFiles }));
      this.emptyPrompt();
    } else {
      const chat: Chat = {
        id: crypto.randomUUID(),
        title: 'Newly generated chat',
        model: 'gpt-3.5-turbo',
        systemMessage: 'You are ChatGPT, a large language model trained by OpenAI.',
        temperature: 0.7,
        createdAt: new Date(),
        updatedAt: new Date(),
        fromIESEKnowledge: this.fromIESEKnowledge,
        files: this.tempFiles.map(getChatFileFromFile),
        messages: [
          {
            content: 'You are ChatGPT, a large language model trained by OpenAI.',
            role: 'system' as Role,
            finished: false,
            fromIESEKnowledge: this.fromIESEKnowledge,
          },
          {
            content: message,
            role: 'user' as Role,
            finished: true,
            fromIESEKnowledge: this.fromIESEKnowledge,
          },
        ],
      };
      this.store.dispatch(ChatDataActions.addChat({ chat, mock: this.mock, shortcut: null, files: this.tempFiles }));
      this.router.navigate(['', 'chat', chat.id]).then();
      this.emptyPrompt();
    }
  }

  protected stopMessage() {
    if (this.activeChat) {
      const payload = { chat: this.activeChat, manually: true };
      this.store.dispatch(ChatDataActions.completeMessageStream(payload));
    }
  }

  protected onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  private emptyPrompt() {
    this.currentMessage = '';
    this.textareaResize.forceResize();
    this.store.dispatch(ChatDataActions.clearFiles());
  }
}
