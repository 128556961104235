import { ShortcutCard } from '@iese-chatbot/common-utils';
import * as Shortcuts from './shortcuts.data';

export const shortcutCards: ShortcutCard[] = [
  {
    title: 'Create a new academic content',
    shortcuts: [Shortcuts.TEACHING_NOTE, Shortcuts.TECHNICAL_NOTE, Shortcuts.ARTICLE],
    helpMessage: 'TBD',
    visible: true,
  },
  {
    title: 'Create a syllabus proposal',
    shortcuts: [Shortcuts.SYLLABUS],
    helpMessage:
      'Design a detailed proposal for a new program or course. This will include learning objectives, course structure, required materials, and evaluation methods, giving a clear blueprint for academic success.',
    visible: true,
  },
  {
    title: 'Abstract creation and keyword extraction',
    shortcuts: [Shortcuts.ABSTRACT_KEYWORDS_TEXT, Shortcuts.ABSTRACT_KEYWORDS_FILES],
    helpMessage:
      'Summarize your content efficiently and identify key terms. This tool provides concise abstracts and extracts relevant keywords, making your research and documents more accessible and searchable.',
    visible: true,
  },
  {
    title: 'Translate this content',
    shortcuts: [Shortcuts.TRANSLATIONS_TEXT, Shortcuts.TRANSLATIONS_FILES],
    helpMessage:
      'Translate your academic materials while adhering to our style guide. This ensures that your content maintains its original intent and quality across different languages, facilitating broader dissemination and understanding.',
    visible: true,
  },
];
