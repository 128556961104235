import { HttpClient } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { XhrMockService } from '../services/xhr-mock.service';
import { XhrRestService } from '../services/xhr-rest.service';
import { XHR_SERVICE } from './xhr-service.token';

function provideMockXhrService(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: XHR_SERVICE,
      useClass: XhrMockService,
    },
  ]);
}

function provideRestXhrService(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: XHR_SERVICE,
      useClass: XhrRestService,
      deps: [HttpClient],
    },
  ]);
}

export function provideXhrService(mock: boolean) {
  if (mock) {
    return provideMockXhrService();
  } else {
    return provideRestXhrService();
  }
}
