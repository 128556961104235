import { Injectable } from '@angular/core';

declare global {
  interface Window {
    MathJax?: any;
  }
}

@Injectable({ providedIn: 'root' })
export class MathJaxLoader {
  private mathJaxUrl = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
  private mathJaxDefined = false;
  private mathJaxPromise?: Promise<void>;

  loadMathJax(): Promise<void> {
    if (this.mathJaxPromise) {
      return this.mathJaxPromise;
    }

    this.mathJaxPromise = new Promise<void>((resolve, reject) => {
      if (window.MathJax) {
        resolve();
        return;
      }

      this.setupMathJaxConfig(); // importante configurar antes

      const script = document.createElement('script');
      script.src = this.mathJaxUrl;
      script.onload = () => {
        this.mathJaxDefined = true;
        resolve();
      };
      script.onerror = (err) => reject(err);
      document.head.appendChild(script);
    });

    return this.mathJaxPromise;
  }

  isMathJaxLoaded(): boolean {
    return this.mathJaxDefined;
  }

  private setupMathJaxConfig(): void {
    // se usa [mjdisplay] y no <mjdisplay> porque MathJax parece ignorar <mjdisplay>
    // al ser una tag html no reconocida
    window.MathJax = {
      tex: {
        displayMath: [
          ['[mjdisplay]', '[/mjdisplay]'],
          ['$$\n', '\n$$'],
        ],
        inlineMath: [
          ['[mjinline]', '[/mjinline]'],
          ['$ ', ' $'],
        ],
      },
      svg: { fontCache: 'global' },
    };
  }
}
