import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppState, ChatDataActions, selectActiveChatFiles, selectTempFiles } from '@iese-chatbot/chat-data-access';
import { ChatFile, FileMimetypePipe, FileSizePipe } from '@iese-chatbot/common-utils';
import { FileIconDirective } from '@iese-chatbot/ui-design-system';
import { Store } from '@ngrx/store';
import { ChipModule } from 'primeng/chip';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'icbc-upload-files-list',
  standalone: true,
  templateUrl: './upload-files-list.component.html',
  styles: [':host { width: 100%; svg {width: 24px; height: 24px;}}'],
  imports: [
    ChipModule,
    OverlayPanelModule,
    FileSizePipe,
    DatePipe,
    FileMimetypePipe,
    ScrollPanelModule,
    ToastModule,
    FileIconDirective,
  ],
})
export class UploadFilesListComponent {
  public tempFiles: File[] = [];
  public chatFiles: ChatFile[] = [];
  protected selectedFile: File | ChatFile | null = null;

  constructor(private store: Store<AppState>) {
    this.store
      .select(selectActiveChatFiles)
      .pipe(takeUntilDestroyed())
      .subscribe((chatFiles) => {
        this.chatFiles = chatFiles ? chatFiles.slice() : [];
      });
    this.store
      .select(selectTempFiles)
      .pipe(takeUntilDestroyed())
      .subscribe((tempFiles) => {
        this.tempFiles = tempFiles ? tempFiles.slice() : [];
      });
  }

  protected removeFile(file: File, overlay: OverlayPanel) {
    this.store.dispatch(ChatDataActions.removeFile({ file }));
    this.hideFileInfo(overlay);
  }

  protected showFileInfo(file: File | ChatFile, overlay: OverlayPanel, event?: Event) {
    if (event) {
      overlay.hide();
      this.selectedFile = file;
      overlay.show(event);
    }
  }

  protected hideFileInfo(overlay: OverlayPanel) {
    overlay.hide();
  }

  protected refreshOverlay(file: File | ChatFile, overlay: OverlayPanel, event?: Event) {
    if (!this.selectedFile || this.selectedFile !== file) {
      this.showFileInfo(file, overlay, event);
    }
  }
}
