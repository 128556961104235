import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectSidebarVisible, UiActions } from '@iese-chatbot/ui-state';
import { Observable } from 'rxjs';
import { BdcWalkModule } from 'bdc-walkthrough';
import { BdcWalkService } from 'bdc-walkthrough';

@Component({
  selector: 'icbc-sidebar-new-chat',
  standalone: true,
  imports: [CommonModule, RouterLink, BdcWalkModule],
  templateUrl: './sidebar-new-chat.component.html',
})
export class SidebarShortcutComponent {
  protected sidebarVisible$: Observable<boolean>;
  constructor(private store: Store, protected bdcWalkService: BdcWalkService) {
    this.sidebarVisible$ = this.store.select(selectSidebarVisible);
  }
  sidebarHideMobile() {
    this.store.dispatch(UiActions.sidebarHideMobile());
  }
  endOnboardingTour() {
    this.bdcWalkService.setTaskCompleted('dialogStep1', true);
    this.bdcWalkService.setTaskCompleted('dialogStep2', true);
    this.bdcWalkService.setTaskCompleted('popupStep3', true);
    this.bdcWalkService.setTaskCompleted('popupStep4', true);
    this.bdcWalkService.setTaskCompleted('popupStep5', true);
    // this.bdcWalkService.setTaskCompleted('popupStep6', true);
    // this.bdcWalkService.setTaskCompleted('popupStep7', true);
    this.bdcWalkService.setTaskCompleted('dialogStep8', true);
    this.bdcWalkService.setTaskCompleted('popupStep9', true);
    // this.bdcWalkService.setTaskCompleted('popupStep10', true);
    this.bdcWalkService.setTaskCompleted('popupStep11', true);
    this.bdcWalkService.setTaskCompleted('dialogStep12', true);
    this.bdcWalkService.setTaskCompleted('dialogStep13', true);
  }
}
