import { FormControl } from '@angular/forms';
import { FileAwareForm } from '../generic-form/file-aware.form';

export const DEFAULT_ABSTRACT_KEYWORDS_SUBJECT = '';

export class AbstractKeywordsForm extends FileAwareForm {
  constructor(fileAwareFields: string[]) {
    super({
      subject: new FormControl<string>(DEFAULT_ABSTRACT_KEYWORDS_SUBJECT),
      usingFiles: new FormControl<boolean>(false),
    });

    if (fileAwareFields.length > 0) {
      this.configureConditionalValidators({ fileAwareFields });
    }
  }
}
