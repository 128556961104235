import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileMimetype',
  standalone: true,
})
export class FileMimetypePipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case 'application/pdf':
        return 'PDF document';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.ms-word.document.macroEnabled.12':
      case 'application/vnd.ms-word.template.macroEnabled.12':
        return 'Microsoft Word document';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.openxmlformats-officedocument.presentationml.template':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
        return 'Microsoft PowerPoint presentation';
      default:
        return 'Unknown file type';
    }
  }
}
