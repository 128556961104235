import { FormControl, Validators } from '@angular/forms';
import { FileAwareForm } from '../generic-form/file-aware.form';

export const DEFAULT_TEACHING_NOTE_CASE_TITLE = '';
export const DEFAULT_TEACHING_NOTE_LEARNING_OBJECTIVE = '';
export const DEFAULT_TEACHING_NOTE_DURATION = 75;
export const DEFAULT_TEACHING_NOTE_INTENDED_AUDIENCE = '';

export class TeachingNoteForm extends FileAwareForm {
  constructor(fileAwareFields: string[]) {
    super({
      caseTitle: new FormControl<string>(DEFAULT_TEACHING_NOTE_CASE_TITLE, [Validators.required]),
      learningObjective: new FormControl<string>(DEFAULT_TEACHING_NOTE_LEARNING_OBJECTIVE, [Validators.required]),
      duration: new FormControl<number>(DEFAULT_TEACHING_NOTE_DURATION, [Validators.min(1), Validators.max(999)]),
      intendedAudience: new FormControl<string>(DEFAULT_TEACHING_NOTE_INTENDED_AUDIENCE, [Validators.required]),
      usingFiles: new FormControl<boolean>(false),
    });

    if (fileAwareFields.length > 0) {
      this.configureConditionalValidators({ fileAwareFields });
    }
  }
}
