import { FormGroup, ValidatorFn } from '@angular/forms';
import { FormUtils } from '../../../utils/form.utils';

export class FileAwareForm extends FormGroup {
  protected configureConditionalValidators(config: {
    fileAwareFields: string[];
    validators?: ValidatorFn | ValidatorFn[] | null;
  }) {
    FormUtils.configureConditionalValidators({
      ...config,
      usingFiles: false,
      form: this,
    });

    this.get('usingFiles')?.valueChanges.subscribe((value) => {
      FormUtils.configureConditionalValidators({
        ...config,
        usingFiles: value,
        form: this,
      });
    });
  }
}
