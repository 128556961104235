import { Inject, Injectable } from '@angular/core';
import { MSAL_INSTANCE, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventType, IPublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';
import { Logger } from '@iese-chatbot/common-utils';
import { Store } from '@ngrx/store';
import { AUTH_CONFIG, AuthConfig } from '../config';
import { AuthActions } from '../state/actions/auth.actions';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    @Inject(MSAL_INSTANCE) private instance: IPublicClientApplication,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
    private store: Store,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  async initialize(): Promise<void> {
    await this.instance.initialize();
    const accounts = this.instance.getAllAccounts();
    if (accounts.length > 0) {
      // TODO limpiar interpolación con /.default, ya que es algo que se reusa
      //  en varios lugares
      this.store.dispatch(AuthActions.storeAuthData({ account: accounts[0] }));
      const request: SilentRequest = {
        scopes: [`${this.authConfig.msal.auth.clientId}/.default`],
        account: accounts[0],
      };
      this.instance
        .acquireTokenSilent(request)
        .then((response) => {
          const account = response.account;
          Logger.debug('MSAL instance', 'acquireTokenSilent', account);
          this.store.dispatch(AuthActions.storeAuthData({ account }));
        })
        .catch((error) => {
          Logger.error('MSAL instance', 'Silent token acquisition failed:', error);
        });
    }

    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            Logger.debug('MSAL Broadcast Service', 'msalSubject$', event.eventType, event);
            this.store.dispatch(
              AuthActions.storeAuthData({ account: (event.payload as AuthenticationResult).account }),
            );
            break;
        }
      },
    });
  }

  async login(): Promise<void> {
    return this.instance.loginRedirect();
  }

  async logout(): Promise<void> {
    return this.instance.logoutRedirect();
  }
}
