export interface ChatFile {
  lastModified: number;
  name: string;
  size: number;
  type: string;
}

export const getChatFileFromFile = (file: File): ChatFile => {
  return {
    lastModified: file.lastModified,
    name: file.name,
    size: file.size,
    type: file.type,
  };
};
