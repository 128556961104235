<div *ngIf="chat" class="chat-messages-container">

  <div *ngFor="let message of chat.messages; last as last" [class]="'chat-message-row role-' + message.role">

    <div class="chat-message-avatar" *ngIf="message.role === 'assistant' ">
      <svg ieseLogo></svg>
    </div>

    @if (last && showWaitMessage) {
      <div class="chat-message-container">
        <div class="chat-message">
          <div class="chat-message-notification info">
            <i class="pi pi-spin pi-spinner"></i>
            <small>Sorry, this is taking longer than expected. We’re still working on it!</small>
          </div>
        </div>
      </div>
    } @else {
      <div class="chat-message-container">
        <div class="chat-message" [libMathJax]="message.content | mdParse"></div>
        <div class="chat-message-citations"
             *ngIf="message.citations?.length && !generatingMessage && !chat.files?.length">
          <strong>References:</strong>
          <button *ngFor="let citation of message.citations; let i = index" (click)="selectCitation(citation)">
            <span>({{ i + 1 }})</span>
            <span>{{ citation.title ?? citation.filepath }}</span>
          </button>
        </div>
        <!--<div class="chat-message-actions" *ngIf="message.role === 'assistant' && (!last || !generatingMessage)">
          <span class="iese-rounded-button pi pi-clone"></span>
        </div>-->
      </div>
    }
  </div>
</div>

<icbc-citation *ngIf="!!selectedCitation" [citation]="selectedCitation"
               (closeCitation)="selectCitation(undefined)">
</icbc-citation>
