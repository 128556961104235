import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectTempFiles } from '@iese-chatbot/chat-data-access';
import { Subject, takeUntil } from 'rxjs';
import { GenericFormComponent } from './generic-form.component';

@Component({
  selector: 'icbc-file-aware-form',
  template: '',
  standalone: true,
})
export abstract class FileAwareFormComponent extends GenericFormComponent implements OnInit, OnDestroy {
  protected abstract fieldNames: string[];
  protected usingFiles = false;
  protected usingContent = false;
  protected _unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  protected initializeSubscriptions(): void {
    this.store
      .select(selectTempFiles)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((tempFiles) => {
        this.usingFiles = tempFiles.length > 0;
        this.form.get('usingFiles')?.setValue(this.usingFiles);
      });

    this.fieldNames.forEach((fieldName) => {
      this.form
        .get(fieldName)
        ?.valueChanges.pipe(takeUntil(this._unsubscribe$))
        .subscribe((value) => {
          this.usingContent = value && value.length > 0;
        });
    });
  }
}
