<form class="p-fluid" [formGroup]="form" (ngSubmit)="submitForm()" uidsDragDropFile>

  <div class="form-fieldset" uidsDragDropFile>
    <div class="form-field form-row">
      <label class="form-label" for="case-title">Case title</label>
      <input class="p-inputtext form-input" id="case-title" type="text" pInputText
             formControlName="caseTitle" (keydown)="handleKeyup($event)" />
    </div>

    <div class="form-field form-row">
      <label class="form-label" for="learning-objective">Learning objective</label>
      <input class="p-inputtext form-input" id="learning-objective" type="text" pInputText
             formControlName="learningObjective" (keydown)="handleKeyup($event)" />
    </div>

    <div class="form-field form-row">
      <label class="form-label" for="duration">Duration <small>(in minutes)</small></label>
      <input class="p-inputtext form-input" id="duration" type="number" pInputText
             formControlName="duration" (keydown)="handleKeyup($event)" />
    </div>

    <div class="form-field form-row">
      <label class="form-label" for="intended-audience">Intended audience</label>
      <input class="p-inputtext form-input" id="intended-audience" type="text" pInputText
             formControlName="intendedAudience" (keydown)="handleKeyup($event)" />
    </div>

    <div class="form-field form-row">
      <label class="form-label">Upload file(s)</label>
      <icbc-upload-files-button></icbc-upload-files-button>
    </div>
    <icbc-upload-files-list></icbc-upload-files-list>
  </div>

  <div class="form-actions">
    <button class="iese-button" (click)="close()">
      <span>Cancel</span>
    </button>
    <button class="iese-button primary" (click)="clear()">
      <i class="pi pi-delete-left"></i>
      <span>Clear</span>
    </button>
    <button class="iese-button primary" type="submit" [ngClass]="{ 'disabled': !form.valid}">
      <i class="pi pi-send"></i>
      <span>Send</span>
    </button>
  </div>

</form>
