import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, ChatDataActions, selectTempFileErrors } from '@iese-chatbot/chat-data-access';
import { Store } from '@ngrx/store';
import { Message, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'icbc-chat-errors',
  standalone: true,
  templateUrl: './chat-errors.component.html',
  imports: [ToastModule],
})
export class ChatErrorsComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, private messageService: MessageService) {}

  ngOnInit(): void {
    this.store
      .select(selectTempFileErrors)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((errors) => {
        // Sin el setTimeout, el messageService recibe los mensajes correctamente pero no hace trigger el p-toast
        if (errors && errors.length > 0) {
          const duration = 10 * 1000;
          // TODO mover mapeo a error-utils?
          const errorMessages: Message[] = errors.map((e) => ({
            severity: 'error',
            summary: 'Error',
            detail: e,
            closable: true,
            icon: 'pi pi-exclamation-circle',
            life: duration,
          }));
          this.messageService.clear();
          this.messageService.addAll(errorMessages);
          setTimeout(() => this.store.dispatch(ChatDataActions.clearFilesErrors()), duration);
        } else {
          this.messageService.clear();
        }
      });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
