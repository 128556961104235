import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../states/app.state';
import { ChatDataActions } from '../actions/chat-data.actions';
import { selectChatDataLoading, selectChatEntities, selectChatIds } from '../selectors/chat-data.selectors';

export const chatIdGuard: CanActivateFn = (route) => {
  const store = inject(Store<AppState>);
  const router = inject(Router);
  const chatId = route.paramMap.get('chatId');

  if (chatId) {
    return store.select(selectChatIds).pipe(
      take(1),
      tap(async entities => {
        if (Object.keys(entities).length === 0) {
          store.dispatch(ChatDataActions.fetchChatHistory());
        }
      }),
      switchMap(entities => {
        // @ts-expect-error chatId is a string
        if (Object.keys(entities).length > 0 && entities[chatId]) {
          return of(true);
        } else {
          return combineLatest([
            store.select(selectChatEntities),
            store.select(selectChatDataLoading),
          ]).pipe(
            take(1),
            switchMap(([entities, loading]) => {
              if (loading) {
                return store.select(selectChatEntities).pipe(
                  map(entities => !!entities[chatId]),
                  tap(exists => {
                    if (!exists) {
                      router.navigate(['/']).then();
                    }
                  }),
                  catchError(() => {
                    router.navigate(['/']).then();
                    return of(false);
                  }),
                );
              } else {
                if (entities[chatId]) {
                  return of(true);
                } else {
                  router.navigate(['/']).then();
                  return of(false);
                }
              }
            }),
            catchError(() => {
              router.navigate(['/']).then();
              return of(false);
            }),
          );
        }
      }),
      catchError(() => {
        router.navigate(['/']).then();
        return of(false);
      }),
    );
  }

  return of(true);
};
