import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { AppState, ChatDataActions, selectTempFiles } from '@iese-chatbot/chat-data-access';
import { FileUtils } from '@iese-chatbot/common-utils';
import { Store } from '@ngrx/store';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'icbc-upload-files-button',
  standalone: true,
  imports: [CommonModule, BadgeModule, ReactiveFormsModule, TooltipModule],
  templateUrl: './upload-files-button.component.html',
})
export class UploadFilesButtonComponent {
  protected files: File[] = [];
  protected readonly FileUtils = FileUtils;

  constructor(private store: Store<AppState>) {
    store
      .select(selectTempFiles)
      .pipe(takeUntilDestroyed())
      .subscribe((files) => {
        this.files = files ? files.slice() : [];
      });
  }

  protected async onFileBrowse(event: Event | null) {
    if (!event) return;

    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files) return;
    const files = Array.from(fileInput.files);

    this.store.dispatch(ChatDataActions.addFileList({ tempFiles: files }));
    fileInput.value = '';
    fileInput.files = null;
  }
}
