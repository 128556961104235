import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropFileDirective } from '@iese-chatbot/ui-design-system';
import { InputTextModule } from 'primeng/inputtext';
import { UploadFilesButtonComponent } from '../../../components/upload-files-button/upload-files-button.component';
import { UploadFilesListComponent } from '../../../components/upload-files-list/upload-files-list.component';
import { FileAwareFormComponent } from '../generic-form/file-aware-form.component';
import { AbstractKeywordsForm } from './abstract-keywords.form';

@Component({
  selector: 'icbc-abstract-keywords',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    DragDropFileDirective,
    UploadFilesButtonComponent,
    UploadFilesListComponent,
  ],
  templateUrl: './abstract-keywords.component.html',
})
export class AbstractKeywordsComponent extends FileAwareFormComponent {
  protected override fieldNames = ['subject'];
  protected override form: FormGroup = new AbstractKeywordsForm(this.fieldNames);

  protected override buildPrompt(): string {
    if (this.usingFiles) {
      return `
You are the editor of a prestigious business school.
Your task is to provide a clear and engaging summary of the attached file(s)
that highlight the main points and keeps the audience interested, as well as keywords.
    `;
    } else {
      return `
You are the editor of a prestigious business school.
Your task is to provide a clear and engaging summary of ${this.form.value.subject}
that highlight the main points and keeps the audience interested, as well as keywords.
    `;
    }
  }
}
